import { render, staticRenderFns } from "./HistoricalNotificationsGrid.vue?vue&type=template&id=3a4e8aa8&scoped=true"
import script from "./HistoricalNotificationsGrid.vue?vue&type=script&lang=js"
export * from "./HistoricalNotificationsGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4e8aa8",
  null
  
)

export default component.exports